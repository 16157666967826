var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { checked: _vm.isChecked },
      style: { height: _vm.height, width: _vm.width },
      on: { click: _vm.toggle },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.label) + "\n  "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isChecked,
            expression: "isChecked",
          },
        ],
        attrs: { type: "checkbox", name: _vm.name, hidden: "hidden" },
        domProps: {
          value: _vm.value,
          checked: Array.isArray(_vm.isChecked)
            ? _vm._i(_vm.isChecked, _vm.value) > -1
            : _vm.isChecked,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.isChecked,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.value,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.isChecked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.isChecked = $$c
            }
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }