<template>
  <div @click="toggle" :class="{ checked: isChecked }">
    {{label}}
    <input
      type="checkbox"
      :name="name"
      :value="value"
      hidden="hidden"
      v-model="isChecked"
    />
  </div>
</template>

<script>
export default {
  name: 'EffortlessTenetOption',
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    toggle() {
      this.isChecked = !this.isChecked;
      this.$emit('updateSelectedTenets', { id: this.value, name: this.label });
    },
  },
  mounted() {
    this.isChecked = this.checked;
  },
  created() {
    if (this.isChecked) {
      this.$emit('updateSelectedTenets', { id: this.value, name: this.label });
    }
  },
};
</script>

<style lang="scss" scoped>
  div {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $LincolnGrayLight;
    color: $LincolnGrayDark;
    padding: 1em;
    margin: 0.1em;
    font-size: 0.8em;
    text-align: center;

    .checked {
      background-color: $LincolnGrayDark;
      color: $LincolnGrayLighter;
    }
  }
</style>
