<template>
  <div @click="toggle" :style="{ height: height, width: width }" :class="{ checked: isChecked }">
    {{label}}
    <input type="checkbox" :name="name" :value="value" hidden="hidden" v-model="isChecked" />
  </div>
</template>

<script>
export default {
  name: 'BusinessAreaOption',
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
    height: {
      type: String,
      default: '100px',
    },
    width: {
      type: String,
      default: '100px',
    },
  },
  data() {
    return {
      isChecked: true,
    };
  },
  methods: {
    toggle() {
      this.isChecked = !this.isChecked;
      this.$emit('updateSelectedAreas', { id: this.value, name: this.label });
    },
  },
  mounted() {
    this.isChecked = this.checked;
  },
};
</script>

<style lang="scss" scoped>
  div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $LincolnGrayLight;
    color: $LincolnGrayDark;
    border-radius: 50px;
    text-transform: uppercase;
    margin: 0.1em;

    .checked {
      background-color: $LincolnGrayDark;
      color: $LincolnGrayLighter;
    }
  }
</style>
