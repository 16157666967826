<template>
  <div class="container">
    <div class="large-12 medium-12 small-12 cell">
      <label class="file-select">
        <input type="file" id="files" ref="files" multiple
               v-on:change="handleFilesUpload()"
               accept=".gif, .jpg,.jpeg, .png, .doc, .docx, .pdf, .xlx, .xlsx, .mp3, .mp4" />
      </label>
    </div>
    <div class="large-12 medium-12 small-12 cell" v-if="files.length > 0">
      <div v-for="(file, index) in files" :key="index" class="file-listing">
        {{ file.name }}
        <span class="remove-file" @click="removeFile(index)">Remove</span>
      </div>
    </div>
    <br>
    <div class="large-12 medium-12 small-12 cell">
      <button class="file-select-button" type="button" @click="addFiles()" v-html="copy"></button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultipleFiles',
  props: {
    copy: {
      type: String,
      required: false,
      default: '+ Attachments(s)',
    },
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload() {
      const uploadedFiles = this.$refs.files.files;
      [...uploadedFiles].forEach((v, k) => {
        this.files.push(uploadedFiles[k]);
      });
      this.$emit('input', this.files);
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
  },
};
</script>
<style scoped lang="scss">

  .file-select > input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  span.remove-file {
    color: red;
    cursor: pointer;
    float: right;
  }

  .file-select-button {
    display: inline-block;
    border: none;
    font-size: 14px;
    text-align: center;
    margin: 0em 0em 1em;
    padding: 1em;
    text-transform: uppercase;
    background-color: $LincolnOrange;
    color: white;
    width: $buttonWidth;
  }
</style>
