var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "large-12 medium-12 small-12 cell" }, [
      _c("label", { staticClass: "file-select" }, [
        _c("input", {
          ref: "files",
          attrs: {
            type: "file",
            id: "files",
            multiple: "",
            accept:
              ".gif, .jpg,.jpeg, .png, .doc, .docx, .pdf, .xlx, .xlsx, .mp3, .mp4",
          },
          on: {
            change: function ($event) {
              return _vm.handleFilesUpload()
            },
          },
        }),
      ]),
    ]),
    _vm.files.length > 0
      ? _c(
          "div",
          { staticClass: "large-12 medium-12 small-12 cell" },
          _vm._l(_vm.files, function (file, index) {
            return _c("div", { key: index, staticClass: "file-listing" }, [
              _vm._v("\n      " + _vm._s(file.name) + "\n      "),
              _c(
                "span",
                {
                  staticClass: "remove-file",
                  on: {
                    click: function ($event) {
                      return _vm.removeFile(index)
                    },
                  },
                },
                [_vm._v("Remove")]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
    _c("br"),
    _c("div", { staticClass: "large-12 medium-12 small-12 cell" }, [
      _c("button", {
        staticClass: "file-select-button",
        attrs: { type: "button" },
        domProps: { innerHTML: _vm._s(_vm.copy) },
        on: {
          click: function ($event) {
            return _vm.addFiles()
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }